import { NgClass } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatListItemLine, MatListItemTitle, MatListOption, MatSelectionList } from '@angular/material/list';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import _ from 'lodash';
import { AuthService } from '../../services/auth.service';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { SheetApplicationInstallComponent } from '../sheet-application-install/sheet-application-install.component';
import { UiDownloadsComponent } from '../ui-downloads/ui-downloads.component';
import { UiNavsheetBtnComponent } from '../ui-navsheet-btn/ui-navsheet-btn.component';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';

@Component({
    selector: 'ui-navsheet-root',
    templateUrl: './ui-navsheet-root.component.html',
    styleUrls: ['./ui-navsheet-root.component.css'],
    standalone: true,
  imports: [UiNavsheetBtnComponent, MatIconButton, MatIcon, UiSectionHeaderComponent, MatRipple,
    NgClass, MatButton, MatSelectionList, MatListOption, MatListItemTitle, MatListItemLine,
    MatSlideToggle, UiDownloadsComponent]
})
export class UiNavsheetRootComponent implements OnInit {

  @Output('onClose') onClose = new EventEmitter<any>();

  public get text(): any { return this.me.session.texts.components['dlg-navigation']; }
  public get msg(): any { return this.me.session.texts.components['common']; }
  public get deviceId(): string { return this.me.session.deviceId; }

  constructor(public me: MeService,
              public auth: AuthService,
              public ssv: SelectService,
              private router: Router,
              private bottomSheet: MatBottomSheet) {

  }

  public section: string = 'main';

  public contentClass(section: string): string {
    const visible = this.section == section;
    return `content ${visible ? 'content-visible' : 'content-hidden'}`;
  }

  public get title(): string {

    switch (this.section) {

      case 'content':
        return this.msg.nav?.content;

      case 'shop':
        return this.msg.nav?.shop;

      case 'admin':
        return this.msg.nav?.admin;

      case 'download':
        return this.msg.nav?.downloads;

      case 'signin':
        return `Sign In @ ${this.businessName}`;

      case 'user':
      case 'main':
      default:
        return this.me.userAtDomain;
    }

  }

  ngOnInit(): void {

    // this.loadAccount();

  }

  nav(path: string, extras?: NavigationBehaviorOptions) {
    this.onClose.emit();
    this.router.navigateByUrl(path, extras);
  }

  //public get anyWinery(): boolean {
  //  return this.account?.domains != null &&
  //    this.account.domains.organizations.length > 0;
  //}

  //navigateDomain(domainId: string) {

  //  //if (domain.domainId == this.me.domain)
  //  //  return;

  //  //this.me.executeOperation('browse.domain.navigate', {
  //  //  domainId: domain.domainId
  //  //}).subscribe();

  //}

  onReloadPage() {
    this.onClose.emit();
    if (this.me.currentPage)
      this.me.currentPage.reloadPage();
  }

  onReloadDomain() {
    this.onClose.emit();
    this.me.initSession(this.me.session.domainId, true)
      .then(() => this.me.reload()
        .then(() => this.router.navigateByUrl('/')));
  }

  onReloadCss() {
    this.onClose.emit();
    this.me.initSession(this.me.session.domainId, false, true)
      .then(() => this.me.reload()
        .then(() => this.router.navigateByUrl('/')));
  }

  // sign in/out
  signIn(provider: string = null) {
    this.onClose.emit();
    this.me.signInDomain(provider);
  }

  signOut() {
    this.onClose.emit();
    this.me.signOut();
  }

  public get en(): boolean { return _.startsWith(this.me.session.locale, 'en'); }
  public get de(): boolean { return _.startsWith(this.me.session.locale, 'de'); }
  public get fr(): boolean { return _.startsWith(this.me.session.locale, 'fr'); }
  public get es(): boolean { return _.startsWith(this.me.session.locale, 'es'); }
  public get it(): boolean { return _.startsWith(this.me.session.locale, 'it'); }
  public get cs(): boolean { return _.startsWith(this.me.session.locale, 'cs'); }
  public get sk(): boolean { return _.startsWith(this.me.session.locale, 'sk'); }
  public get domain(): string { return this.me.session.domainId; }
  public get businessName(): string { return this.me.session.title; }

  //////////////////////////
  // theme

  public get mode(): boolean {
    return document.body.classList.contains('dark');
  }

  public onModeChange(event: MatSlideToggleChange) {

    if (event.checked)
      document.body.classList.add('dark');
    else
      document.body.classList.remove('dark');

 }

  //////////////////////////
  // app

  public install(event: Event = null) {

    this.bottomSheet.open(SheetApplicationInstallComponent);

    if (event)
      event.preventDefault();

    return false;

  }

}
