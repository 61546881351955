
@if (me.isMember) {

  <div class="searchbar" matAutocompleteOrigin #origin="matAutocompleteOrigin">

    <mat-form-field class="search-btn">

      <mat-label>Šarže, odrůda, ročník</mat-label>

      <input matInput type="text"
             [formControl]="searchControl"
             [matAutocomplete]="auto"
             [matAutocompleteConnectedTo]="origin">

      @if (isClearVisible) {
      <button mat-icon-button matSuffix (click)="clear()">
        <mat-icon>clear</mat-icon>
      </button>
      }

      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="onSelected($event)">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">
            <span class="label-small sku">{{option.sku}}</span>
            <span class="label-small man">{{option.manufacturer}}</span><br/>
            <span class="label-large">{{option.name}}</span>
          </mat-option>
        }
      </mat-autocomplete>

      <mat-hint>{{stateHint}}</mat-hint>

    </mat-form-field>

    <button mat-icon-button [matMenuTriggerFor]="cartMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

  </div>

  <mat-menu #cartMenu="matMenu">

      <button mat-menu-item [matMenuTriggerFor]="exportMenu" [disabled]="isEmpty">
        <span>Export</span>
      </button>

      <!--<mat-divider></mat-divider>-->

      <button mat-menu-item [matMenuTriggerFor]="stateMenu">
        <span>Hledej</span>
      </button>

      <button mat-menu-item [matMenuTriggerFor]="viewMenu" [disabled]="isEmpty">
        <span>Nastavení</span>
      </button>

  </mat-menu>

  <mat-menu #stateMenu="matMenu">

      <button mat-menu-item (click)="switchState('production')">
        <mat-icon>{{stateFilter.production ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span>Výroba</span>
      </button>

      <button mat-menu-item (click)="switchState('readyForSale')">
        <mat-icon>{{stateFilter.readyForSale ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span>Připraveno</span>
      </button>

      <button mat-menu-item (click)="switchState('sale')">
        <mat-icon>{{stateFilter.sale ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span>Prodej</span>
      </button>

      <button mat-menu-item (click)="switchState('soldOut')">
        <mat-icon>{{stateFilter.soldOut ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span>Vyprodané</span>
      </button>

  </mat-menu>

  <mat-menu #viewMenu="matMenu">

      <button mat-menu-item (click)="switchShopUI()">
        <mat-icon>{{showShopUI ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span>Shop UI</span>
      </button>

  </mat-menu>

  <mat-menu #exportMenu="matMenu">

      @for (template of cart?.templates; track $index) {

      <button mat-menu-item (click)="export(template.id)">
        <span>{{template.name}}</span>
      </button>

      }

  </mat-menu>

}

@if (!isEmpty) {

  @if (showShopUI) {

  <div class="toolbar">

    <div class="total label-large">

      <div class="total-text">{{text.items.total}}:</div>

      @if (cart.itemsPrice) {
      <ui-price [price]="cart.itemsPrice" [hint]="false"></ui-price>
      }

    </div>

    <div class="spacer"></div>

    @if (cart.canShop) {
    <button mat-flat-button [disabled]="noItemsToOrder" (click)="onNext.emit()">
      <span class="toolbar-button-content label-large">
        {{text.buy.order}}
        <mat-icon>chevron_right</mat-icon>
      </span>
    </button>
    }

  </div>

  }


  <div class="items">

    @for (item of items; track item) {
    <ui-cart-item [cart]="cart" [item]="item"></ui-cart-item>
    }

    @if (showShopUI) {
    <div class="price-description label-medium">{{text.items.priceDescription}}<br />{{priceDescription}}</div>
    }

    @if (items?.length) {
    <button mat-button class="clearbtn" color="accent" (click)="onRemoveAll()">{{text.items.delete}}</button>
    }

  </div>

}

