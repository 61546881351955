import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatListItemMeta, MatListOption, MatSelectionList } from '@angular/material/list';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import _ from 'lodash';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';

@Component({
  selector: 'ui-cart-user-lists',
  templateUrl: './ui-cart-user-lists.component.html',
  styleUrls: ['./ui-cart-user-lists.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, MatHint, MatIconButton, MatIcon,
    MatSelectionList, MatListOption, MatListItemMeta, FormsModule, MatSuffix,
    MatMenuTrigger, MatIconButton, MatMenu, MatMenuContent, MatMenuItem, MatDivider]
})
export class UiCartUserListsComponent implements OnInit {

  @Output('onSetList') onSetList = new EventEmitter<any>();

  @ViewChild('lists', { static: false }) lists: MatSelectionList;

  public get msg(): any { return this.me.texts.components['common']; }

  public userLists: any;
  public manage: boolean = false;

  constructor(public me: MeService, public ssv: SelectService) { }

  ngOnInit() {

    this.me.call<any>('browse.user.lists.get', {

    }).subscribe((userLists) => {

      this.userLists = userLists;

    });

  }

  public get execState(): string {

    if (this.manage)
      return null;

    // is any list selected
    if (this.lists?.selectedOptions.selected.length &&
        this.storedList != this.lists.selectedOptions.selected[0].value) {

      // if cart contains items
      if (this.ssv.selection.items.length)
        return 'list-add';
      else
        return 'list-set';

    }

    // is selection named?
    if (this.ssv.selection.name) {

      // is selection stored?
      if (this.ssv.selection.id) {

        const storedList = this.storedList;
        if (storedList && this.ssv.selection.name != storedList.name)
          return 'list-new';
        else
          return 'list-save';
      }
      else
        return 'list-new';

    }

    return null;
  }

  public get canExec(): boolean {

    if (this.manage)
      return false;

    const state = this.execState;

    if (state == 'list-save' && !this.isChanged)
      return false;

    if (!state)
      return false;

    return  true;

  }

  public get execHint(): string {

    if (this.manage)
      return 'Práce se seznamy';

    switch (this.execState) {

      case 'list-add':
        return 'Přidat vybraný seznam do košíku';

      case 'list-set':
        return 'Vložit vybraný seznam do košíku';

      case 'list-save':
        return this.canExec ? 'Uložit seznam' : '';

      case 'list-new':
        return 'Přidat do seznamů';

      default:
        return '';
    }

  }


  public get execIcon(): string {

    switch (this.execState) {

      case 'list-add':
        return 'add_shopping_cart';

      case 'list-set':
        return 'shopping_cart_checkout';

      case 'list-save':
        return 'save';

      case 'list-new':
        return 'add_circle';

      default:
        return '';
    }

  }



  public onExec() {


    switch (this.execState) {

      case 'list-add':
        // Use lodash to create a map of ids from the first array
        const cartIds = new Set(this.ssv.selection.items.map(item => item.id));
        // Filter the second array to include only objects with ids not in the first array
        const uniqueFromList = this.selectedList.items.filter((item: any) => !cartIds.has(item.id));
        // Concatenate the two arrays
        this.ssv.selection.items = _.concat(this.ssv.selection.items, uniqueFromList);
        this.ssv.save();
        this.onSetList.emit();
        return;

      case 'list-set':
        this.ssv.selection.id = this.selectedList.id;
        this.ssv.selection.name = this.selectedList.name;
        this.ssv.selection.items = this.selectedList.items;
        this.ssv.save();
        this.onSetList.emit();
        return;

      case 'list-save':

        // get stored list
        const storedList = this.storedList;
        if (storedList) {

          // update by current selection
          storedList.items = this.ssv.selection.items;

          this.onSave();

        }

        return;

      case 'list-new':

          // gerenate shor unique id
          this.ssv.selection.id = this.me.generateShortId();

          // add current selection
          this.userLists.my.push({
            id: this.ssv.selection.id,
            name: this.ssv.selection.name,
            items: this.ssv.selection.items
          });

          this.onSave();

        return;

      default:
        return;
    }



  }


  public onLoad(option: MatListOption) {

    this.ssv.selection.id = option.value.id;
    this.ssv.selection.name = option.value.name;
    this.ssv.selection.items = option.value.items;

  }

  public onSave() {

    this.me.call<any>('browse.user.lists.set', {

      userLists: this.userLists

    }).subscribe((userLists) => {

      this.userLists = userLists;

    });

  }

  public isCurrent(list: any) {

    if (list.id)
      return list.id == this.ssv.selection.id;

    return false;
  }

  public get selectedList(): any {

    if (this.lists.selectedOptions.selected.length)
      return this.lists.selectedOptions.selected[0].value;

    return false;

  }

  public isSelected(list: any) {

    return this.selectedList === list;

  }

  public get storedList(): any {

    if (this.userLists)
      return _.find(this.userLists.my, (list: any) => list.id == this.ssv.selection.id);

    return null;

  }

  public get isChanged(): boolean {

    // if current selection is stored
    if (this.ssv.selection.id && this.userLists) {

      // find it in stored lists
      const storedList = this.storedList;
      if (storedList) {

        if (storedList.items.length != this.ssv.selection.items.length)
          return true;

        // check the same item ids and count
        for (var i = 0; i < storedList.items.length; i++)
          if (storedList.items[i].id != this.ssv.selection.items[i].id ||
            storedList.items[i].count != this.ssv.selection.items[i].count)
            return true;

        return false;
      }

    }

    return false;
  }

  public onDelete() {

    // collect ids of the deleted lists
    var ids = new Set(_.map(this.lists.selectedOptions.selected, option => option.value.id));

    // copy only not deleted lists
    this.userLists.my = this.userLists.my.filter((list: any) => !ids.has(list.id));

    // if current
    if (ids.has(this.ssv.selection.id)) {

      this.ssv.selection.id = null;
      this.ssv.selection.name = null;

    }

    // save
    this.onSave();

    // stop managemenet
    this.manage = false;
  }

  public onImport() {

    this.me.call<any>('ad4znovin.user.lists.import')
      .subscribe((userLists) => {

      this.userLists = userLists;

    });

  }


}
