
@if (operation) {

    <div class="statebar">
      <div class="title-small">{{executionState}}</div>
      <span class="spacer"></span>
      <button mat-raised-button color="accent" class="execbtn" [disabled]="isDisabled" (click)="execute()">{{msg.op.execute}}</button>
    </div>

    @if (operation.form) {

    <ui-form #uiForm class="form-content"
             [docId]="operation.form.doc.id"
             [form]="operation.form"
             [data]="operation.form.doc.data">
    </ui-form>

    }

    <p class="body-medium">{{operation.description}}</p>

    @if (operation.validation) {

      <mat-list>
        @for (record of operation.validation.records; track $index) {
          <mat-list-item>
            <mat-icon matListItemIcon [ngClass]="icon(record)" [fontSet]="fontSet(record)">{{icon(record)}}</mat-icon>
            <div matListItemTitle><span [ngClass]="icon(record)">{{record.severity}}:</span> {{record.title}}</div>
            <div matListItemLine [innerHtml]="record.text"></div>
          </mat-list-item>
        }
      </mat-list>

    }

    @if (operation.journal) {

     <p class="body-medium">Executed with the result: {{operation.status}}</p>
     <mat-list>
        @for (record of operation.journal.records; track $index) {
          <mat-list-item>
            <mat-icon matListItemIcon [ngClass]="icon(record)" [fontSet]="fontSet(record)">{{icon(record)}}</mat-icon>
            <div matListItemTitle><span [ngClass]="icon(record)">{{record.severity}}:</span> {{record.title}}</div>
            <div matListItemLine [innerHtml]="record.text"></div>
          </mat-list-item>
        }
      </mat-list>

    }

}

