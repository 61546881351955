
@switch (itemsState) {

  @case ('production') {

    <div class="body-medium description">
      {{text.from}}: <span class="production">{{text.production}}</span><br />
      {{text.to}}: <span class="readyForSale">{{text.readyForSale}}</span>
    </div>
    <div class="btn-row">
      <button mat-flat-button color="primary" (click)="toReadyForSale()">=> {{text.readyForSale}}</button>
    </div>

  }

  @case ('readyForSale') {

    <div class="body-medium description">
      {{text.from}}: <span class="readyForSale">{{text.readyForSale}}</span><br />
      {{text.to}}: <span class="sale">{{text.sale}}</span>
    </div>
    <div class="btn-row">

      <mat-form-field>
        <input matInput [matDatepicker]="picker" [formControl]="toSaleFrom" placeholder="{{text.date}}">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="toSale(false)">=> {{text.sale}}</button>
      <button mat-flat-button color="primary" (click)="toSale(true)">=> {{text.saleImmediate}}</button>

    </div>

  }

  @case ('sale') {

    <div class="body-medium description">
      {{text.from}}: <span class="sale">{{text.sale}}</span><br />
      {{text.to}}: <span class="soldOut">{{text.soldOut}}</span>
    </div>
    <div class="btn-row">

      <mat-form-field>
        <input matInput [matDatepicker]="picker" [formControl]="toSoldOutFrom" placeholder="{{text.date}}">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="toSoldOut(false)">=> {{text.soldOut}}</button>
      <button mat-flat-button color="primary" (click)="toSoldOut(true)">=> {{text.soldOutImmediate}}</button>

    </div>

  }

  @case ('soldOut') {

    <div class="body-medium description">{{text.soldOutOnly}}</div>

  }

  @default {
      <div class="body-medium description">{{text.mixed}}</div>
  }

}

  <div class="items">

    @for (item of items; track $index) {
    <ui-cart-item [item]="item"></ui-cart-item>
    }

  </div>

