import { NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTooltip } from '@angular/material/tooltip';
import * as _ from 'lodash';
import { CopyClipboardDirective } from '../../directives/copy-clipboard.directive';
import { AuthService } from '../../services/auth.service';
import { MeService } from '../../services/me.service';
import { NavService } from '../../services/nav.service';
import { FormFieldTextCopyComponent } from '../form-field-text-copy/form-field-text-copy.component';
import { FormFieldTextEditComponent } from '../form-field-text-edit/form-field-text-edit.component';
import { UiSectionHeaderComponent } from '../ui-section-header/ui-section-header.component';
import { UiUserDetailComponent } from '../ui-user-detail/ui-user-detail.component';
import { SheetUserAccountDeleteComponent } from '../sheet-user-account-delete/sheet-user-account-delete.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
    selector: 'ui-user-account',
    templateUrl: './ui-user-account.component.html',
    styleUrls: ['./ui-user-account.component.css'],
    standalone: true,
  imports: [NgIf, UiSectionHeaderComponent, FormFieldTextEditComponent, FormFieldTextCopyComponent,
    MatSlideToggle, UiUserDetailComponent, MatButton, MatIcon, MatTooltip, CopyClipboardDirective,
    MatFormField, MatLabel, MatInput, MatError, ReactiveFormsModule, MatIconButton, MatSuffix]
})
export class UiUserAccountComponent implements OnInit {

  @ViewChild('tooltip', { static: false }) toolTip: MatTooltip;
  @ViewChild('deviceIdInput', { static: false, read: MatInput }) deviceIdInput: MatInput;
  @ViewChild('restoreInput', { static: false }) restoreInput: ElementRef<HTMLInputElement>;

  public account: any;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['ui-user-account']; }

  public get en(): boolean { return _.startsWith(this.me.locale, 'en'); }
  public get de(): boolean { return _.startsWith(this.me.locale, 'de'); }
  public get fr(): boolean { return _.startsWith(this.me.locale, 'fr'); }
  public get es(): boolean { return _.startsWith(this.me.locale, 'es'); }
  public get it(): boolean { return _.startsWith(this.me.locale, 'it'); }
  public get cs(): boolean { return _.startsWith(this.me.locale, 'cs'); }
  public get sk(): boolean { return _.startsWith(this.me.locale, 'sk'); }

  public get deviceId(): string { return this.me.session.deviceId; }
  public get deviceIdOriginal(): string { return this.me.getDeviceIdOriginal(); }
  public get userId(): string { return this.me.session.userId; }
  public userName(user: any): string {

    if (_.isString(user.name))
      return user.name;

    return user.id;

  }

  // userId of an original user not signed in - visitor
  private originalIdKey = 'user:id:original';
  public get originalId(): string { return localStorage.getItem(this.originalIdKey) || null; }
  public set originalId(userId: string) {

    if (userId)
      localStorage.setItem(this.originalIdKey, userId);
    else
      localStorage.removeItem(this.originalIdKey);

  }

  public get isVisitor(): boolean {
    return _.find(this.account.user.roles, { id: 'gss/domain/role/visitor' }) != undefined;
  }
  public get isRegistered(): boolean {
    return _.find(this.account.user.roles, { id: 'gss/domain/role/registered' }) != undefined;
  }
  public get isAnonymousVisitor(): boolean {
    return this.isVisitor && !this.isRegistered;
  }
  public get isRegisteredVisitor(): boolean {
    return this.isVisitor && this.isRegistered;
  }
  public get isRegisterVisible(): boolean {
    return this.isVisitor && this.me.session.domainId != 'ad4wine.com';
  }

  public loadingAccount: boolean = false;
  public updatingAccount: boolean = false;
  public copyTooltipText: string = null;

  constructor(public me: MeService,
    public auth: AuthService,
    private nav: NavService) { }


  ngOnInit(): void {

    this.loadingAccount = true;

    this.me.call('browse.user.account.get')
      .subscribe((account) => {

        this.account = account;
        this.loadingAccount = false;

      });

    this.deviceIdCtrl.setValue(this.deviceId);
    this.deviceIdCtrl.disable();
    this.copyTooltipText = this.msg.clipboard.copy;

  }

  saveUserName(ctrl: FormFieldTextEditComponent) {

    this.updatingAccount = true;

    this.me.call('browse.user.account.update', {
        property: 'name',
        value: ctrl.currentValue
      })
      .subscribe(null, null, () => {

        ctrl.saving = false;
        this.updatingAccount = false;

      });

  }

  saveUserEmail(ctrl: FormFieldTextEditComponent) {

    this.updatingAccount = true;

    this.me.call('browse.user.account.update', {
        property: 'email',
        value: ctrl.currentValue
      })
      .subscribe(null, null, () => {

        ctrl.saving = false;
        this.updatingAccount = false;

      });

  }

  public onSignIn() {
    this.nav.sideNavToggle('signin');
  }

  ////////////////////////////////////////////
  // deviceId ctrl handling
  public matcher = new InstantErrorStateMatcher();
  public deviceIdCtrl = new FormControl('', [
    Validators.required,
    Validators.pattern('\\s*v-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\\s*')
  ]);

  public get errDeviceId() {
    if (this.deviceIdCtrl.invalid) {
      if (this.deviceIdCtrl.hasError('required'))
        return this.text.errUserIdRequired;
      else
      if (this.deviceIdCtrl.hasError('pattern'))
        return this.text.errUserIdValid;
    }

    return null;
  }

  public onChangeId() {

    this.deviceIdCtrl.enable();
    this.deviceIdInput.focus();

  }

  public get onCommitIdDisabled(): boolean {

    if (!this.deviceIdCtrl.valid)
      return true;

    if (_.trim(this.deviceIdCtrl.value) == this.deviceId)
      return true;

    return false;

  }

  public onCommitId() {

    // get new id
    var newId = _.trim(this.deviceIdCtrl.value);

    // check same id
    if (this.deviceId == newId)
      return;


    this.me.call<void>('browse.user.account.set', {

      old: this.deviceId,
      new: newId

    }).subscribe(() => {

      // store original id
      this.originalId = this.deviceId;

      // set current device id
      this.me.setDeviceId(newId);
      this.me.session.deviceId = newId;

      // open bottom sheet with set message
      this.me.openBottomSheet(this.text.msgSetId, 'done', 5000)
        .afterDismissed()
        .subscribe(() => {

          // reload app for the new user
          this.me.closeAllDialogs();
          this.me.initSession(this.me.session.domainId)
            .then(() => this.nav.to('/'));

        });

    }, () => {

      // reset value
      this.deviceIdCtrl.setValue(this.deviceId);

      // open bottom sheet with error message
      this.me.openBottomSheet(this.text.msgErrorId, 'error', 5000);

    });

    this.deviceIdCtrl.disable();

  }

  public onCancelId() {

    this.deviceIdCtrl.setValue(this.deviceId);
    this.deviceIdCtrl.disable();

  }

  public onResetId() {

    if (!this.originalId)
      return;

    this.me.call<void>('browse.user.account.set', {

      old: this.deviceId,
      new: this.originalId

    }).subscribe(() => {

      // set current user id
      this.me.setDeviceId(this.originalId);
      this.me.session.deviceId = this.originalId;

      // show we changed value
      this.deviceIdCtrl.setValue(this.originalId);

      // reset original id
      this.originalId = null;

      // open bottom sheet with set message
      this.me.openBottomSheet(this.text.msgResetId,'done', 5000)
        .afterDismissed()
        .subscribe(() => {

          // reload app for the new user
          this.me.closeAllDialogs();
          this.me.initSession(this.me.session.domainId)
            .then(() => this.nav.to('/'));

        });

    }, () => {

      // open bottom sheet with error message
      this.me.openBottomSheet(this.text.msgErrorId, 'error', 5000);

    });
  }

  public onArchive() {

    this.me.call<any>('browse.user.account.archive')
      .subscribe((accountData) => {

        const jsonString = JSON.stringify(accountData);
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `${this.userId}@${this.me.session.domainId}@${(new Date()).toISOString()}.json`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();

      });

  }

  public onRestore() {

    const file = this.restoreInput.nativeElement.files[0]; // Get the selected file
    if (!file)
      return;

    const reader = new FileReader();
    const _this = this;
    reader.onload = function (e) {

      const text = e.target.result as string;

      // Handle the parsed JSON data
      _this.me.call<void>('browse.user.account.restore', {

        data: text,

      }).subscribe(() => {

        // open bottom sheet with set message
        _this.me.openBottomSheet(_this.text.msgResetSuccess, 'info', 3000);

      }, () => {

        // open bottom sheet with error message
        _this.me.openBottomSheet(_this.text.msgResetError, 'error', 5000);

      });

    };

    reader.onerror = function (e) {
      console.error("File could not be read! Code " + e.target.error.message);
    };

    reader.readAsText(file); // Read the file as text

  }

  public onDelete() {

    // do not allow to delete non-original Id
    if (this.originalId)
      return;

    this.me.call<void>('browse.user.account.delete', {

      accountId: this.deviceId,

    }).subscribe(() => {

      // open bottom sheet with set message
      this.me.openBottomSheetType(SheetUserAccountDeleteComponent, {
        accountId: this.deviceId
      });

    }, () => {

      // open bottom sheet with error message
      this.me.openBottomSheet(this.text.msgErrorId, 'error', 5000);

    });
  }

  public copied() {

    this.copyTooltipText = this.msg.clipboard.copied;
    this.toolTip.show();

    setTimeout(() => {

      this.toolTip.hide();

      setTimeout(() => {

        this.copyTooltipText = this.msg.clipboard.copy;

      }, 1000);

    }, 2000);

  }

  public share() {

    const shareData: ShareData = {
      title: this.msg.user.id,
      text: this.deviceId,
    };

    navigator.share(shareData);

  }

}
