import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { MeService } from '../../services/me.service';
import { MatListItem, MatListItemLine, MatListItemMeta, MatListItemTitle, MatNavList } from '@angular/material/list';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ui-downloads',
  templateUrl: './ui-downloads.component.html',
  styleUrls: ['./ui-downloads.component.css'],
  standalone: true,
  imports: [MatNavList, MatListItem, MatListItemTitle, MatListItemLine, MatListItemMeta,
            MatIconButton, MatIcon, DatePipe, FileSizePipe]
})
export class UiDownloadsComponent {

  public downloads: any = null;

  constructor(public me: MeService) {

  }

  ngOnInit() {

    this.me.call<any>('browse.domain.downloads')
           .subscribe((data: any) => {

      this.downloads = data.downloads;

    });

  }

  open(uri: string) {

    this.me.mediaDownload(uri);

  }

}
