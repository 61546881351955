
<mat-nav-list>

  @for(download of downloads; track $index) {

    <mat-list-item>
       <div matListItemTitle>
        <span class="label-large">{{download.id}}</span>
       </div>
       <div matListItemLine>
        <span class="label-small dimmed lastmodified">{{download.lastModified | date:'medium':null:me.locale}}</span>
        <span class="label-small dimmed filesize">{{download.contentLength | fileSize}}</span>
       </div>
       <button mat-icon-button (click)="open(download.url)" matListItemMeta>
          <mat-icon>file_download</mat-icon>
       </button>
    </mat-list-item>

  }

</mat-nav-list>
