import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow, MatChipsModule } from '@angular/material/chips';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatOption, MatSelect } from '@angular/material/select';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { FileSelectDirective } from '../../directives/file-select.directive';
import { FileUploader } from '../../directives/file-uploader.class';
import { AuthService } from '../../services/auth.service';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';

@Component({
  selector: 'ui-cart-export',
  templateUrl: './ui-cart-export.component.html',
  styleUrls: ['./ui-cart-export.component.css'],
  standalone: true,
  imports: [MatButton, MatMenuTrigger, MatMenu, MatMenuItem, MatIcon, MatFormField, MatInput, FormsModule,
    MatLabel, MatSelect, MatOption, MatChipGrid, MatChipRow, MatChipRemove, MatChipInput, CdkTextareaAutosize,
    FileSelectDirective, MatChipsModule,  MatHint]
})
export class UiCartExportComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.texts.components['ui-export']; }

  public view: any;

  public get cultures(): any[] { return this.view?.cultures; }
  public get cultureIdx(): number { return this.view?.cultureIdx; }
  public get hasManyCultures(): boolean { return this.view && this.view.cultures.length > 1; }

  public get templates(): any[] { return this.view?.templates; }
  public get hasTemplates(): boolean { return this.templates ? this.templates.length > 0 : false; }

  public get currentCulture(): any { return this.cultureIdx >= 0 ? this.cultures[this.cultureIdx] : null; }
  public set currentCulture(culture) {
    this.view.cultureIdx = _.findIndex(this.cultures, function (c) { return c === culture; });
  }

  private _template: any;
  public get template(): any { return this._template; }
  public set template(template: any) {
    this._template = template;
  }

  public exporting: boolean = false;
  public sending: boolean = false;
  public get canExport(): boolean { return (!this.exporting && !this.sending) ? true : false; }
  public get canSend(): boolean { return (this.emailto && this.emailto.length && this.canExport) ? true : false; }
  public get btnExportText(): string { return this.exporting ? this.text.exporting : this.text.download; }
  public get btnSendText(): string { return this.sending ? this.text.sending : this.text.send; }

  // e-mail
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

  private _emailto: Array<string> = [];
  public get emailto(): Array<string> { return this._emailto; }

  private _subject: string;
  public get subject(): string { return this._subject; }
  public set subject(value: string) { this._subject = value; }

  private _content: string;
  public get content(): string { return this._content; }
  public set content(value: string) { this._content = value; }

  // media uploader
  public uploader: FileUploader;
  public get isWorking(): boolean { return this.isUploading || this.isRefreshing; }
  public get isUploading(): boolean { return this.uploader && this.uploader.isUploading; }
  public get uploadBtnText(): string { return this.isUploading ? this.text.uploading : this.text.upload; }
  public isRefreshing: boolean = false;
  protected uploadOperation: any;

  constructor(private me: MeService, private ssv: SelectService, private auth: AuthService) {

  }

  ngOnInit() {

    // init uploader
    this.uploader = new FileUploader({
      autoUpload: true,
      url: '/api/browse/export',
      parametersBeforeFiles: true,
      removeAfterUpload: true
    });

    // hook form att
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData) => {

      // serialize execution request
      var execRequest = JSON.stringify(this.me.createExecutionRequest('browse.documents.export', {
        docIds: this.ssv.ids,
        locale: this.currentCulture ? this.currentCulture.locale : null,
      }));

      form.append('execRequest', execRequest);

    };

    this.uploader.onCompleteItem = (item, response) => {

      // decode response
      var result = JSON.parse(response);
      if (result) {

        // do we have to download?
        var mediaUri = result['download'];
        if (mediaUri)
          this.me.mediaDownload(mediaUri);

      }

    }

    this.me.call<any>('browse.user.cart.export.get', {

        docIds: this.ssv.ids

      })
      .subscribe((view) => {

        this.view = view;

        // set default template
        if (this.templates && this.templates.length) {
          this.template = this.templates[0];
        }

        this.isRefreshing = false;

      });


  }

  add(event: MatChipInputEvent): void {

    const input = event.chipInput;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.emailto.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.clear();
    }
  }

  remove(email: string): void {

    const index = this.emailto.indexOf(email);

    if (index >= 0) {
      this.emailto.splice(index, 1);
    }
  }

  public onExport() {

    // export single item
    this.exporting = true;

    this.export(this.template.id)
      .subscribe((result: any) => {

        if (result.rendered)
          this.me.mediaDownloadAuthenticated(`api/${result.url}`, result.fileName);

        this.exporting = false;

      });

  }

  public onSend() {

    // send single item
    this.sending = true;

    this.export(this.template.id)
      .subscribe((result) => {

        // we have result file name
        if (result.fileName) {

          // send via e-mail
          this.me.call<any>('ad4wine.operations.document.user.send', {
            'to': this.emailto,
            'subject': this.subject,
            'text': this.content ? this.content : ' ',
            'documentId': result.fileName
          }).subscribe(() => {

            this.sending = false;

            // open bottom sheet with sent message
            // this.bottomSheet.open(SheetExportSentComponent);

          });

        }

      });

  }

  public export(templateId: string): Observable<any> {

    // current locale
    var locale = this.currentCulture ? this.currentCulture.locale : null;

    return this.me.call<any>("browse.documents.export", {

      docIds: this.ssv.ids,
      templateId: templateId,
      locale: locale

    });

  }


  uploadTemplate() {

    // init uploader with current token
    this.auth.acquireToken().then(token => {

      this.uploader.setOptions({
        authToken: "Bearer " + token
      });

      this.fileInput.nativeElement.click();

    });

  }

  // https://stackoverflow.com/questions/59870335/ng2-file-upload-not-allowing-me-to-add-same-doc-after-ive-removed-it-from-que
  onFileClick(event: any) {
    event.target.value = '';
  }


  // operation data storage
  private get operationKey(): string {
    return `operation:${this.me.session.domainId}:ui-cart-export`;
  }

  public loadOperationData(): any {

    var value = localStorage.getItem(this.operationKey);
    if (value) {

      var operationData = JSON.parse(value);
      return operationData;

    }
  }

  public saveOperationData(data: any) {
    localStorage.setItem(this.operationKey, JSON.stringify(data));
  }

  public resetOperationData() {
    localStorage.removeItem(this.operationKey);
  }


}
