import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelContent, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemIcon, MatListItemLine, MatListItemTitle } from '@angular/material/list';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import _ from 'lodash';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiCartExportComponent } from '../ui-cart-export/ui-cart-export.component';
import { UiCartStateComponent } from '../ui-cart-state/ui-cart-state.component';
import { UiCartTaskComponent } from '../ui-cart-task/ui-cart-task.component';
import { UiFormComponent } from '../ui-form/ui-form.component';
import { UiToolbarTopComponent } from '../ui-toolbar-top/ui-toolbar-top.component';

@Component({
    selector: 'ui-cart-tasks',
    templateUrl: './ui-cart-tasks.component.html',
    styleUrls: ['./ui-cart-tasks.component.css'],
    standalone: true,
  imports: [MatAccordion, MatExpansionPanel, MatExpansionPanelContent, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription,
      MatList, MatListItem, MatIcon, MatListItemIcon, MatListItemTitle, MatListItemLine, MatButton, NgClass,
      UiFormComponent, UiCartTaskComponent, AsyncPipe, UiToolbarTopComponent, MatIconButton,
      MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem, MatDivider,
      UiCartExportComponent, UiCartStateComponent]
})
export class UiCartTasksComponent implements OnInit {


  public view: any = null;

  public operationState(operation: any): string {

    let val = operation.validation;

    // no validation
    if (val != null) {

      // invalid
      if (!val.isValid)
        return null;

      // valid and current
      if (val.isCurrent === false)
        return "UPDATE";

      // we have validation and it is OK
      if (val.isCurrent === true)
        return "OK";
    }

    return null;
  }


  public get msg(): any { return this.me.session.texts.components['common']; }

  constructor(public me: MeService, private ssv: SelectService) {

  }

  ngOnInit() {

    this.me.call<any>('browse.documents.operations.list', {

      docIds: this.ssv.ids

    }).subscribe((data: any) => {

      this.view = data;

    });

  }

  update(result: any) {

      // update all other operations
      this.me.call<any>('browse.documents.operations.list', {

        docIds: this.ssv.ids

      }).subscribe((data: any) => {

        _.forEach(data.operations, (op) => {

          let exop = _.find(this.view.operations, (o) => o.id == op.id);
          if (exop)
            exop.validation = op.validation;

        });


      });


  }

}
