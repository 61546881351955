
  <div class="statebar">

    <mat-form-field>
      <mat-label>{{text.template}}</mat-label>
      <mat-select [(ngModel)]="template" [disabled]="templates?.length == 1">
        @for (tmpl of templates; track tmpl) {
        <mat-option [value]="tmpl">{{tmpl.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    @if (hasManyCultures) {

    <button mat-button class="exec-button" [matMenuTriggerFor]="locMenu" [disabled]="isWorking">
      {{currentCulture.text}}
    </button>

    <mat-menu #locMenu="matMenu">

      @for (culture of cultures; track $index) {

      <button mat-menu-item (click)="currentCulture = culture">
        <mat-icon [style.visibility]="currentCulture === culture?'visible':'hidden'">check</mat-icon>
        <span>{{culture.text}}</span>
      </button>

      }

    </mat-menu>

    }

  </div>


  @if (template) {

  <div class="execbar">
    <button mat-flat-button class="exec-button" color="primary" (click)="onExport()" [disabled]="!canExport || isUploading">{{btnExportText}}</button>
    <!--<button mat-flat-button class="exec-button" [class.spinner]="isUploading" [disabled]="isUploading" (click)="uploadTemplate()">{{text.uploadTemplate}}</button>-->
  </div>

  }

  <mat-form-field class="fullwidth">
    <mat-label>{{text.emailTo}}</mat-label>
    <mat-chip-grid #chipList>
      @for (email of emailto; track email) {
      <mat-chip-row selectable="true" removable="true" (removed)="remove(email)">
        {{email}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      }
      <input [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             matChipInputAddOnBlur="true"
             (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
  </mat-form-field>

  <mat-form-field class="fullwidth">
    <mat-label>{{text.subject}}</mat-label>
    <input matInput [(ngModel)]="subject" />
  </mat-form-field>

  <mat-form-field class="fullwidth">
    <mat-label>{{text.text}}</mat-label>
    <textarea matInput [(ngModel)]="content"
              cdkTextareaAutosize
              cdkAutosizeMinRows="4"
              cdkAutosizeMaxRows="15"></textarea>
  </mat-form-field>

  @if (template) {
  <div class="execbar">
    <button mat-flat-button class="exec-button" color="primary" (click)="onSend()" [disabled]="!canSend">{{btnSendText}}</button>
  </div>
  }

<input #fileInput type="file" ng2FileSelect [uploader]="uploader" style="display:none;"
                  (click)="onFileClick($event)"/>

