import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatFormFieldModule, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import _ from 'lodash';
import { CartItem, CartItemList, ICartItemList } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiCartItemComponent } from '../ui-cart-item/ui-cart-item.component';

@Component({
  selector: 'ui-cart-state',
  templateUrl: './ui-cart-state.component.html',
  styleUrls: ['./ui-cart-state.component.css'],
  providers: [provideNativeDateAdapter()],
  standalone: true,
  imports: [MatButton, MatFormFieldModule, MatInput, MatSuffix, MatDatepickerModule, MatDatepicker, MatDatepickerToggle,
    FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatHint, UiCartItemComponent]
})
export class UiCartStateComponent implements OnInit {

  public get msg(): any { return this.me.session.texts.components['common']; }
  public get text(): any { return this.me.session.texts.components['view-list']?.['tasks']?.['state']; }

  public cart: CartItemList;
  public get items(): Array<CartItem> { return this.cart?.items; }

  constructor(private me: MeService, private ssv: SelectService) {

  }

  ngOnInit() {

    this.reload();

  }

  private reload() {

    this.me.call<ICartItemList>('browse.user.cart.itemlist.get', {

      selectedItems: this.ssv.selection.items,
      itemsOnly: true

    }).subscribe((cart) => {

      this.cart = new CartItemList(cart);

    });

  }

  public get itemsState(): string {

    if (!this.cart?.items?.length)
      return null;

    var state: string = null;
    var mixed = false;
    _.forEach(this.cart.items, (item) => {

      if (_.isString(item.state.state )) {

        if (state == null)
          state = item.state.state;
        else
        if (state != item.state.state)
          mixed = true;
      };

    });

    return mixed ? null : state;
  }

  public toReadyForSale() {

    // to ready to sale
    this.me.call<ICartItemList>('browse.operation.execute', {

        operationId: `/${this.me.session.domainId}/operations/toReadyForSale`,
        data: {
          selectedItems: this.ssv.selection.items
        }

    }).subscribe((cart) => {

      this.cart = new CartItemList(cart);

    });



  }

  public toSaleFrom = new FormControl((new Date()).toISOString());
  public toSale(immediate: boolean) {

      // to sale
      this.me.call<ICartItemList>('browse.operation.execute', {

        operationId: `/${this.me.session.domainId}/operations/toSale`,
        data: {
          selectedItems: this.ssv.selection.items,
          from: this.toSaleFrom.value,
          immediate: immediate
        }

      }).subscribe((cart) => {

        this.cart = new CartItemList(cart);

      });

  }

  public toSoldOutFrom = new FormControl((new Date()).toISOString());
  public toSoldOut(immediate: boolean) {

      // to sold out
      this.me.call<ICartItemList>('browse.operation.execute', {

        operationId: `/${this.me.session.domainId}/operations/toSoldOut`,
        data: {
          selectedItems: this.ssv.selection.items,
          from: this.toSoldOutFrom.value,
          immediate: immediate
        }

      }).subscribe((cart) => {

        this.cart = new CartItemList(cart);

      });

  }


}
