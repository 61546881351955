import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MeService } from '../../services/me.service';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
  selector: 'form-field-auto',
  templateUrl: './form-field-auto.component.html',
  styleUrls: ['./form-field-auto.component.css'],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, MatAutocompleteTrigger, ReactiveFormsModule, MatIcon, MatSuffix, MatError, MatAutocomplete, MatOption],
  inputs: FormFieldBaseComponent.baseInputs
})
export class FormFieldAutoComponent extends FormFieldBaseComponent implements OnInit {

  @ViewChild('autoCompleteInput', { static: false }) input: ElementRef;

  constructor(private me: MeService) {
    super();
  }

  //public get msg(): any { return this.me.texts.components['common']; }

  public autoOptions: any[] = [];
  public loading: boolean = false;
  private loadingText: string = null;
  private changedTextWhileLoading: string = null;

  public get currentTitle() {

    if (this.isArrayItem) {

      var value = this.getCurrentValue();
      if (_.isObjectLike(value) && _.startsWith(value.value, '/'))
        return this.idFromPath(value.value);

    }

      return this.label || this.title;
  }

  ngOnInit() {

    // build validators
    var validators = [];
    if (this.required) validators.push(Validators.required);

    // build value
    var value = this.getCurrentValue();

    // create field control and set value
    this.fieldCtrl = new FormControl(value, {
      validators: validators,
      updateOn: 'change'
    });

    // set disabled
    if (this.readonly)
      this.fieldCtrl.disable();

    // react to value change
    this.fieldCtrl.valueChanges.pipe(debounceTime(500))
      .forEach((value: any) => {

        // do nothing if invalid
        if (this.fieldCtrl.invalid)
          return;

        // do not promote undefined
        if (_.isUndefined(value))
          return;

        // ignore same value
        if (this.value === value)
          return;

        // if selected option
        if (_.isObjectLike(value)) {

          // set current option value
          this.value = value.value;

          // append to options, if not already there
          if (!this.findOption()) {
            if (!this.options)
              this.options = [];

            this.options.push(value);
          }

        }
        else
        if (_.isString(value)) {

          if (value === '')
            this.value = null;
          else
          if (!this.enumOnly)
            this.value = value;

          // list options
          if (document.activeElement == this.input.nativeElement) {
            this.listValues(value);
          }

        }

      });

    // register control
    this.register(this.fieldCtrl);

  }

  ngOnChanges() {

    if (this.fieldCtrl) {

      // build value
      var value = this.getCurrentValue();

      // set current value
      this.fieldCtrl.setValue(value);

      // set disabled
      if (this.readonly)
        this.fieldCtrl.disable();
    }

  }

  findOption() {
    return _.find(this.options, item => item.value === this.value)
  }

  getCurrentValue() {
    var option = this.findOption();
    if (option)
      return option;
    else
      return this.value;
  }

  listValues(text: string) {

    // do not re-enter
    if (this.loading) {
      if (this.loadingText !== text)
        this.changedTextWhileLoading = text;
      return;
    }
    else {
      this.loadingText = text;
      this.changedTextWhileLoading = text;
    }

    // attribute id
    var attId: string;
    if (_.isString(this.attribute))
      attId = this.attribute;
    else
    if (_.isObject(this.attribute))
      attId = (this.attribute as any).id;
    else
      return;

    // filtering
    this.loading = true;
    this.listAttributeValues(this.formSchema.formId, attId, text, 100, this.currentLocale)
      .subscribe(result => {

        this.autoOptions = result.options;

      }, () => {

        this.loading = false;

      }, () => {

        this.loading = false;

        // reload, if we missed any changes
        if (this.loadingText !== this.changedTextWhileLoading) {
          this.listValues(this.changedTextWhileLoading);
        }
        else {
          this.loadingText = null;
          this.changedTextWhileLoading = null;
        }

      });

  }

  // autocomplete
  displayFn(value: any) {

    if (_.isObjectLike(value))
      return value.text || value.value;
    else
      return value;

  }

  onFocus() {

    var currentValue = this.getCurrentValue()
    if (_.isString(currentValue))
      this.listValues(currentValue);
    else
    if (_.isObjectLike(currentValue) && (currentValue.text || currentValue.value))
      this.listValues(currentValue.text || currentValue.value);
    else
      this.listValues(null);

  }

  onBlur() {

    if (this.enumOnly && this.fieldCtrl.value) {

      // build value
      var value = this.getCurrentValue();

      // re-set current value
      this.fieldCtrl.setValue(value);

    }

  }

  idFromPath(path: string) {
    var segments = _.split(path, '/');
    return segments[segments.length - 1];
  }

  isLink(option: any) {
    return _.startsWith(option.value, '/');
  }

  valueIsLink() {
    var option = this.findOption();
    if (option)
      return this.isLink(option);
    else
      return false;
  }

  // autocomplete support
  public listAttributeValues(formId: string, attributeId: string, text: string, maxCount: number, formLocale: string): Observable<any> {

    // prepare call
    var call = this.me.call<any>("browse.entity.attribute.values", {

      docId: this.docId,
      formId: formId,
      attributeId: attributeId,
      text: text,
      maxCount: maxCount,
      formLocale: formLocale

    });

    return call;
  }

  // autocomplete support
  public listAttributeValuesWithMatch(formId: string, match: string, attributeId: string, text: string, maxCount: number, formLocale: string): Observable<any> {

    // prepare call
    var call = this.me.call<any>("browse.entity.attribute.values", {

      docId: this.docId,
      formId: formId,
      match: match,
      attributeId: attributeId,
      text: text,
      maxCount: maxCount,
      formLocale: formLocale

    });

    return call;
  }


}
