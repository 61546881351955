
<div class="row">

  @if (cart && member) {

    <div class="buttons">

      @if (!viewButtons) {
      <button mat-icon-button (click)="viewButtons = !viewButtons">
        <mat-icon class="more-icon">more_vert</mat-icon>
      </button>
      }
      @else {
      <button mat-icon-button [disabled]="!canMoveUP" (click)="moveItemUP()">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>

      @if (!showShopUI) {
      <button mat-icon-button [disabled]="!canDelete" (click)="deleteItem()">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      }

      <button mat-icon-button [disabled]="!canMoveDOWN" (click)="moveItemDOWN()">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      }

    </div>

  }

  @if (item.image && (!condensed || !member)) {
  <div class="image" [bkgImage]="item.image" (click)="loadDetail()"></div>
  }

  @if (!item.image) {
  <div style="margin-left:16px"></div>
  }
  
  <div class="identification">

    <div class="data" (click)="loadDetail()">

      <div class="label-small title">

        @if (skuVisible) {
        <span class="sku">{{item.sku}}</span>
        }
        @if (stateVisible) {
        <ui-state [state]="item.state"></ui-state><br />
        }
        <span>{{item.manufacturer}}</span><br />
        <span class="label-large name">{{item.name}}</span>
        @if (item.subtitle) {
        <br /><span>{{item.subtitle}}</span>
        }

      </div>

      <div class="spacer"></div>

      @if (item.pricing) {
      <ui-price-product class="label-large price"
                        [hint]="priceHintVisible(item.pricing[0])"
                        [price]="item.pricing[0]"></ui-price-product>
      }

    </div>


    <div class="spacer price note label-small"
         [innerHtml]="item.note.note | lineBreaks"></div>

    @if (ratingVisible) {
    <ui-note-rating class="rating" [note]="item.note"></ui-note-rating>
    }

    @if (showShopUI) {

    <div class="summary">

      <button mat-icon-button (click)="decrementCount()">
      @if (canDecrement) {
        <mat-icon>remove</mat-icon>
      }
      @else {
        <mat-icon>remove_circle</mat-icon>
      }
      </button>

      <div class="label-large quantity">{{item.count}}</div>

      <button mat-icon-button (click)="incrementCount()">
        <mat-icon>add</mat-icon>
      </button>

      @if (item.price) {
      <ui-price-product class="label-large price-total"
                        [class.bold]="!priceHintVisible(item.price)"
                        [hint]="priceHintVisible(item.price)"
                        [price]="item.price"
                        [end]="true"></ui-price-product>
      }

    </div>

    }

  </div>

</div>

<mat-divider class="divider"></mat-divider>
