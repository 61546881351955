
@if (view) {

<mat-accordion>

  <mat-expansion-panel>

    <mat-expansion-panel-header>
      <mat-panel-title>STAV</mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>

      <ui-cart-state></ui-cart-state>

    </ng-template>

  </mat-expansion-panel>

  <mat-expansion-panel>

    <mat-expansion-panel-header>
      <mat-panel-title>EXPORT</mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>

      <ui-cart-export></ui-cart-export>

    </ng-template>

  </mat-expansion-panel>


  @for(operation of view.operations; track $index) {

  <mat-expansion-panel>

    <mat-expansion-panel-header>
      <mat-panel-title>{{operation.name}}</mat-panel-title>
      <mat-panel-description>{{operationState(operation)}}</mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>

      <ui-cart-task [operation]="operation" (onExecuted)="update($event)"></ui-cart-task>

    </ng-template>

  </mat-expansion-panel>

  }

</mat-accordion>

}

