
<div class="topbar topbar-right">

  <button mat-icon-button (click)="onClose.emit()">
    <mat-icon>close</mat-icon>
  </button>

  <span class="title-medium title">{{title}}</span>

</div>

@if (section == 'main') {

<div class="content">

  <ui-navsheet-btn icon="home" [label]="msg.nav.home" [active]="true" (click)="nav('/')"></ui-navsheet-btn>
  @if (ssv.any || !me.isVisitor) {
  <ui-navsheet-btn icon="shopping_cart" [label]="msg.nav.cart" [active]="true" [value]="ssv.totalCount" (click)="nav('/me/cart')"></ui-navsheet-btn>
  }
  @if (me.isAdmin) {
  <ui-navsheet-btn icon="shield" [label]="msg.nav.admin" [forward]="true" (click)="section='admin'"></ui-navsheet-btn>
  }

  <div class="separator"></div>

  <ui-navsheet-btn icon="apps" [label]="msg.nav.content" [forward]="true" (click)="section='content'"></ui-navsheet-btn>
  <ui-navsheet-btn icon="shopping_bag" [label]="msg.nav.products_wines" [forward]="true" (click)="section='shop'"></ui-navsheet-btn>
  <ui-navsheet-btn icon="account_circle" [label]="msg.nav.user" [forward]="true" (click)="section='user'"></ui-navsheet-btn>

  @if (!me.isVisitor) {
  <ui-navsheet-btn icon="file_download" [label]="msg.nav.downloads" [forward]="true" (click)="section='download'"></ui-navsheet-btn>
  }

  @if (me.hasAssistent) {
  <ui-navsheet-btn icon="chat" [label]="msg.nav.assist"></ui-navsheet-btn>
  }

  <div class="separator"></div>

  @if (me.canInstall && !me.isInstalled) {
  <ui-navsheet-btn icon="add_to_home_screen" [label]="msg.app.install.button" (click)="install()"></ui-navsheet-btn>
  }

  @if (me.canShare) {
  <ui-navsheet-btn icon="share" [label]="msg.app.share" (click)="me.share()"></ui-navsheet-btn>
  }

</div>

}
@else if (section == 'content') {

<div class="content">

  <ui-navsheet-btn icon="arrow_back" [label]="msg.nav.main_menu" [active]="true" (click)="section='main'"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.about" (click)="nav('/about')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.contacts" (click)="nav('/contact')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.delivery" (click)="nav('/about/delivery')"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.about_app" class="item-separator" (click)="nav('/about/app')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.legal_terms" (click)="nav('/legal/terms')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.legal_gdpr" (click)="nav('/legal/gdpr')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.legal_cookies" (click)="nav('/legal/cookies')"></ui-navsheet-btn>

</div>

}
@else if (section == 'shop') {

<div class="content">

  <ui-navsheet-btn icon="arrow_back" [label]="msg.nav.main_menu" [active]="true" (click)="section='main'"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.products_wines" (click)="nav('/products/wines')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.products_sets" (click)="nav('/products/sets')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.products_gifts" (click)="nav('/products/packaging')"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.products_new" class="item-separator" (click)="nav('/products/wines?qid=vinarstvisoska.cz%2Fui%2Fqueries%2Fwine%2Fnew')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.products_action" (click)="nav('/products/wines?qid=vinarstvisoska.cz%2Fui%2Fqueries%2Fwine%2Faction')"></ui-navsheet-btn>

</div>

}
@else if (section == 'user') {

<div class="content">

  <ui-navsheet-btn icon="arrow_back" [label]="msg.nav.main_menu" [active]="true" (click)="section='main'"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.user_notes" (click)="nav('/me/notes')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.user_orders" (click)="nav('/me/orders')"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.user_documents" (click)="nav('/me/documents')"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.user_account" class="item-separator" (click)="nav('/me/account')"></ui-navsheet-btn>

</div>

}
@else if (section == 'admin') {

<div class="content">

  <ui-navsheet-btn icon="arrow_back" [label]="msg.nav.main_menu" [active]="true" (click)="section='main'"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.admin_console" [active]="true" (click)="nav('/admin')"></ui-navsheet-btn>

  @if(me.isOwner) {

  <ui-navsheet-btn [label]="msg.nav.admin_portal" [active]="true" (click)="nav('/admin/portal')"></ui-navsheet-btn>

  <ui-navsheet-btn [label]="msg.nav.admin_reload_domain" class="item-separator" (click)="onReloadDomain()"></ui-navsheet-btn>
  <ui-navsheet-btn [label]="msg.nav.admin_reload_css" (click)="onReloadCss()"></ui-navsheet-btn>

  }

</div>

}
@else if (section == 'signin') {

<div class="content">

  <ui-navsheet-btn class="signin-back" icon="arrow_back" [label]="msg.nav.main_menu" [active]="true" (click)="section='main'"></ui-navsheet-btn>

  <div class="heading body-medium">{{msg.auth.providers}}</div>

  <div class="providers label-large">

    <div class="apple" matRipple (click)="signIn('apple.com')">{{msg.auth.apple}}</div>

    <!--<div class="facebook" matRipple (click)="signIn('facebook.com')">{{msg.auth.facebook}}</div>-->

    <div class="google" matRipple (click)="signIn('google.com')">{{msg.auth.google}}</div>

    <div class="microsoft" matRipple (click)="signIn('live.com')">{{msg.auth.microsoftPersonal}}</div>

    <div class="microsoft" matRipple (click)="signIn('commonaad')">{{msg.auth.microsoftWork}}</div>

  </div>

  <ui-section-header #legal label="{{msg.auth.legal}}" [divider]="true" [button]="true"></ui-section-header>

  @if (legal.expanded) {
    @if (en) {
    <div class="body-medium">

      <div>
        By signing in, you agree that {{businessName}} will see following personal data:
        <ul>
          <li>Name</li>
          <li>E-mail</li>
          <li>and your orders placed at {{businessName}}</li>
        </ul>
        <br /><br />
        <b>All other data</b> (including wine evaluation and notes) <b>are strictly private</b> and are not shared
        with wineries, other users or third parties.
      </div>

    </div>
    }
    @else if (de) {
    <div class="body-medium">

      <div>
        Durch Anmeldung erklären Sie sich damit einverstanden, dass {{businessName}} folgende persönliche Daten sieht:
        <ul>
          <li>Name</li>
          <li>E-Mail</li>
          <li>und Ihre bei {{businessName}} getätigten Bestellungen</li>
        </ul>
        <br /><br />
        <b>Alle anderen Daten</b> (einschließlich Weinbewertungen und Notizen) <b>sind streng privat</b>
        und werden nicht an Weingüter, andere Benutzer oder Dritte weitergegeben.
      </div>

    </div>
    }
    @else if (fr) {
    <div class="body-medium">

      <div>
        Grâce à l'inscription, vous acceptez que {{businessName}} voit les données personnelles suivantes:
        <ul>
          <li>Nom</li>
          <li>Courrier électronique</li>
          <li>vos commandes passées à {{businessName}}</li>
        </ul>
        <br /><br />
        <b>Toutes les autres données</b> (y compris l'évaluation des vins et les notes) <b>sont strictement privées</b>
        et ne sont pas partagées avec des caves, d'autres utilisateurs ou des tiers.
      </div>

    </div>
    }
    @else if (es) {
    <div class="body-medium">

      <div>
        A través del registro, usted acepta que {{businessName}} vea los siguientes datos personales:
        <ul>
          <li>Nombre</li>
          <li>Correo electrónico</li>
          <li>sus órdenes puestas en {{businessName}}</li>
        </ul>
        <br /><br />
        <b>Todos los demás datos</b> (incluida la evaluación de los vinos y las notas) <b>son estrictamente privados</b>
        y no se comparten con bodegas, otros usuarios o terceros.
      </div>

    </div>
    }
    @else if (it) {
    <div class="body-medium">

      <div>
        Attraverso la registrazione, accetti che {{businessName}} veda i seguenti dati personali:
        <ul>
          <li>Nome</li>
          <li>E-mail</li>
          <li>i tuoi ordini a {{businessName}}</li>
        </ul>
        <br /><br />
        <b>Tutti gli altri dati</b> (compresa la valutazione del vino e le note) <b>sono strettamente privati</b>
        e non vengono condivisi con cantine, altri utenti o terzi.
      </div>

    </div>
    }
    @else if (cs) {
    <div class="body-medium">

      Přihlášením souhlasíte s tím, že <b>{{businessName}}</b> uvidí následující osobní údaje:
      <ul>
        <li>Jméno, e-mail a vaše objednávky</li>
      </ul>
      <b>Všechny ostatní údaje</b> (včetně hodnocení vín a poznámek) <b>jsou přísně soukromé</b>
      a nejsou sdíleny s vinařstvími, jinými uživateli nebo třetími stranami.
    </div>
    }
    @else if (sk) {
    <div class="body-medium">

      <div>
        Prostredníctvom registrácie súhlasíte s tým, že {{businessName}} uvidí nasledujúce osobné údaje:
        <ul>
          <li>Meno</li>
          <li>E-mail</li>
          <li>vaše objednávky zadané u {{businessName}}</li>
        </ul>
        <br /><br />
        <b>Všetky ďalšie údaje</b> (vrátane vyhodnotenia vín a poznámok) <b>sú prísne súkromné</b>
        a nezdieľajú sa s vinárstvami, inými používateľmi alebo tretími stranami.
      </div>

    </div>
    }
  }
</div>

}
@else if (section == 'download') {

<div class="content">

  <ui-navsheet-btn class="signin-back" icon="arrow_back" [label]="msg.nav.main_menu" [active]="true" (click)="section='main'"></ui-navsheet-btn>

  <ui-downloads></ui-downloads>

</div>

}

@if (section != 'signin') {

<div class="footer footer-right">

  <!--<mat-slide-toggle [checked]="mode" (change)="onModeChange($event)">Light theme</mat-slide-toggle>-->
  
  @if (auth.isSignedIn) {

  <ui-navsheet-btn icon="logout" [label]="msg.nav.user_signout" (click)="signOut()"></ui-navsheet-btn>

  }
  @else {

  <!--<div class="label-medium footer-message" [innerHTML]="msg.user.signinMessage"></div>-->

  <ui-navsheet-btn icon="login" [label]="msg.nav.user_signin" (click)="section='signin'"></ui-navsheet-btn>

  }

</div>

}
