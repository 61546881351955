
<ui-toolbar-top [title]="title" (back)="onBack()"></ui-toolbar-top>


@if (step == 'items') {

  @if (me.isVisitor) {

  <ui-cart-item-list #cartItemList
                     class="step"
                     (onNext)="step = 'delivery'"></ui-cart-item-list>

  }
  @else {

  <mat-tab-group #itemsTabGroup animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start">

    <mat-tab label="Položky">

      <ng-template matTabContent>

        <ui-cart-item-list #cartItemList
                           class="tab-content"
                           (onNext)="step = 'delivery'"></ui-cart-item-list>

      </ng-template>

    </mat-tab>

    <mat-tab label="Seznamy">

      <ng-template matTabContent>

        <ui-cart-user-lists class="tab-content"
                            (onSetList)="itemsTabGroup.selectedIndex = 0"></ui-cart-user-lists>

      </ng-template>

    </mat-tab>

    <mat-tab label="Úlohy">

      <ng-template matTabContent>

        <ui-cart-tasks class="tab-content"></ui-cart-tasks>

      </ng-template>

    </mat-tab>

  </mat-tab-group>

  }

} @else if (step == 'delivery') {

<ui-cart-deliveryoption-list class="step"
                             (onPrevious)="step = 'items'"
                             (onNext)="step = 'form'"></ui-cart-deliveryoption-list>

} @else if (step == 'form') {


<ui-cart-form class="step"
              (onPrevious)="step = 'delivery'"
              (onNext)="step = 'summary'"></ui-cart-form>

} @else if (step == 'summary') {

<ui-cart-summary class="step"
                 (onPrevious)="step = 'form'"
                 (onNext)="cartSent()"></ui-cart-summary>

} @else if (step == 'sent') {

<ui-cart-sent class="step"
              (onNext)="onBack()"></ui-cart-sent>

}

