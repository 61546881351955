import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemIcon, MatListItemLine, MatListItemTitle } from '@angular/material/list';
import _ from 'lodash';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiFormComponent } from '../ui-form/ui-form.component';

function fillNullProperties(target: any, source: any): any {
  return _.mergeWith({}, target, source, (targetValue, sourceValue) => {
    return targetValue === null ? sourceValue : targetValue;
  });
}


@Component({
    selector: 'ui-cart-task',
    templateUrl: './ui-cart-task.component.html',
    styleUrls: ['./ui-cart-task.component.css'],
    standalone: true,
    imports: [MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription,
      MatList, MatListItem, MatIcon, MatListItemIcon, MatListItemTitle, MatListItemLine, MatButton, NgClass,
      UiFormComponent ]
})
export class UiCartTaskComponent implements OnInit {

  @Input('operation') public set operation(op: any) {
    this._operation = op;
    this.initOperationData();
  }
  public get operation(): any {
    return this._operation;
  }
  private _operation: any;

  @Output('onExecuted') public onExecuted = new EventEmitter<any>();

  @ViewChild('uiForm', { static: false }) uiForm: UiFormComponent;

  public get msg(): any { return this.me.session.texts.components['common']; }

  constructor(private me: MeService, private ssv: SelectService) {

  }

  ngOnInit() {

  }


  initOperationData() {

    var formData = this.operation?.form?.doc.data;
    if (formData) {

      var savedData = this.loadOperationData();
      if (savedData) {

        // fill in all attributes with null values in formData
        // which are available in saved data
        this.operation.form.doc.data = fillNullProperties(formData, savedData);

      }

    }

  }

  public get executionState(): string {

    return this.operation.executing ? "Executing..." : this.operation.validation?.state;

  }

  public get isDisabled(): boolean {

    // executing
    if (this.operation.executing)
      return true;

    let val = this.operation.validation;

    // no validation
    if (val && !val.canExecute)
      return true;

    // form
    if (this.uiForm && !this.uiForm.formGroup.valid)
      return true;

    return false;
  }

  public icon(record: any): string {

    switch (record.severity) {

      case "Error":
        return "error";

      case "Warning":
        return "warning";

      case "Info":
        return "info";

      default:
        return null;
    }

  }

  public fontSet(record: any): string {

    if (this.icon(record) == "error")
      return 'material-icons';
    else
      return 'material-icons-outlined';

  }

  public execute() {

    // handle share
    let shareData = this.operation.validation?.share;
    if (shareData) {

      if (navigator.canShare(shareData))
        navigator.share(shareData);

      return;
    }

    this.operation.executing = true;

    // store data
    var data = this.operation.form?.doc.data;
    if (data)
      this.saveOperationData(data);

    // execute operation
    this.me.call<any>('browse.document.operation.execute', {

      docIds: this.ssv.ids,
      operationId: this.operation.id,
      data: this.operation.form?.doc.data

    }).subscribe((result) => {

      // set operation
      this.operation.executing = false;
      this.operation.validation = result.validation;
      this.operation.statusCode = result.statusCode;
      this.operation.status = result.status;
      this.operation.journal = result.journal

      // emit event
      this.onExecuted.emit(result);

    });

  }

  // operation data storage
  private get operationKey(): string {
    return `operation:${this.me.session.domainId}:${this.operation.id}`;
  }

  public loadOperationData(): any {

    var value = localStorage.getItem(this.operationKey);
    if (value) {

      var operationData = JSON.parse(value);
      return operationData;

    }
  }

  public saveOperationData(data: any) {
    localStorage.setItem(this.operationKey, JSON.stringify(data));
  }

  public resetOperationData() {
    localStorage.removeItem(this.operationKey);
  }


}
