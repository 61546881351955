import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ISelectedItem } from '../models/selection.interface';

export class Selection {

  constructor(data: any = null) {
    if (data)
      Object.assign(this, data);
  }

  // items
  public items: Array<ISelectedItem> = [];

  // deliveryOption
  public deliveryOption: string = null;

  // form att
  public formData: any = null;

  // selection id
  public id: string = null;

  // selection name
  public name: string = null;

  // UI related to shopping - items count, total etc.
  public showShopUI: boolean = false;

  public get any(): boolean {
    return this.items.length > 0;
  }

  public get totalCount(): number {
    return _.reduce(this.items, (sum, item) => sum + item.count, 0);
  }

  public get(id: string): ISelectedItem {
    return _.find(this.items, { 'id': id });
  }

  public isSelected(id: string): boolean {
    return this.get(id) != undefined;
  }

  public select(id: string, count: number) {

    var item = this.get(id);
    if (item)
      item.count = count;
    else
      this.items.push({
        id: id,
        count: count
      });

  }

  public getCount(id: string) {

    return this.get(id)?.count;

  }

  public moveItemUP(id: string): boolean {

    var index = _.findIndex(this.items, item => item.id == id);
    if (index > 0) {
      var items = _.pullAt(this.items, [index]);
      this.items.splice(index - 1, 0, items[0]);
      return true;
    }

    return false;
  }

  public moveItemDOWN(id: string): boolean {

    var index = _.findIndex(this.items, item => item.id == id);
    if (index >= 0 && index < this.items.length - 1) {
      var items = _.pullAt(this.items, [index]);
      this.items.splice(index + 1, 0, items[0]);
      return true;
    }

    return false;
  }

  public remove(id: string) {

    _.remove(this.items, item => item.id == id);

  }

  public removeAll() {

    this.id = null;
    this.name = null;
    this.items = [];

  }

}

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  // domain
  public domainId: string;

  // selection
  public selection: Selection = new Selection();

  constructor() {
  }

  public get any(): boolean {
    return this.selection.any;
  }

  public get totalCount(): number {
    return this.selection.totalCount;
  }

  public get ids(): string[] {
    return _.map(this.selection.items, 'id')
  } 

  public get(id: string): ISelectedItem {
    return this.selection.get(id);
  }

  public isSelected(id: string): boolean {
    return this.selection.isSelected(id);
  }

  public select(id: string, count: number) {

    if (count > 0)
      this.selection.select(id, count);
    else
      this.selection.remove(id);

    this.save();

  }

  public getCount(id: string) {

    return this.selection.getCount(id);

  }

  public moveItemUP(id: string) {

    if (this.selection.moveItemUP(id))
      this.save();

  }

  public moveItemDOWN(id: string) {

    if (this.selection.moveItemDOWN(id))
      this.save();

  }

  public remove(id: string) {

    this.selection.remove(id);
    this.save();

  }

  public removeAll() {

    this.selection.removeAll();
    this.save();
  }

  // delivery option
  public getDeliveryOption() {

    return this.selection.deliveryOption;

  }

  public setDeliveryOption(id: string) {

    this.selection.deliveryOption = id;
    this.save();
  }

  // form att
  public getFormData() {

    return this.selection.formData;

  }

  public getName() {

    return this.selection.name;

  }

  public setFormData(formData: any, name: string) {

    this.selection.formData = formData;
    this.selection.name = name;
    this.save();
  }

  // selection storage
  private get selectKey(): string {
    return `select:${this.domainId}`;
  }

  public load(domainId: string): void {

    this.domainId = domainId;
    this.selection = new Selection();

    var value = localStorage.getItem(this.selectKey);
    if (value) {

      var selectionData = JSON.parse(value);
      this.selection = new Selection(selectionData);

    }
  }

  public save() {
    localStorage.setItem(this.selectKey, JSON.stringify(this.selection));
  }

  public reset() {
    localStorage.removeItem(this.selectKey);
  }

}
