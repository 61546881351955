import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteOrigin, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import * as _ from "lodash";
import { Observable, debounceTime, map, startWith } from 'rxjs';
import { CartItem, CartItemList, ICartItemList } from '../../models/cart.class';
import { MeService } from '../../services/me.service';
import { SelectService } from '../../services/select.service';
import { UiCartItemComponent } from '../ui-cart-item/ui-cart-item.component';
import { UiPriceComponent } from '../ui-price/ui-price.component';

@Component({
    selector: 'ui-cart-item-list',
    templateUrl: './ui-cart-item-list.component.html',
    styleUrls: ['./ui-cart-item-list.component.css'],
    standalone: true,
  imports: [UiPriceComponent, MatButton, MatIcon, UiCartItemComponent,
    MatMenuTrigger, MatIconButton, MatMenu, MatMenuContent, MatMenuItem, MatDivider,
    MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AsyncPipe,
    MatAutocompleteOrigin]
})
export class UiCartItemListComponent implements OnInit {

  @Output('onNext') onNext = new EventEmitter<any>();

  public cart: CartItemList;
  public search: any;
  public searchControl = new FormControl('');
  public options: any[];
  public filteredOptions: Observable<any[]>;

  public get msg(): any { return this.me.texts.components['common']; }
  public get text(): any { return this.me.texts.components['view-shoppping-cart']; }

  public get items(): Array<CartItem> { return this.cart?.items; }
  public get isEmpty(): any { return !this.items || this.items.length == 0; }
  public get isSingle(): any { return this.items && this.items.length == 1; }
  public get isMultiple(): any { return this.items && this.items.length > 1; }
  public get itemsToOrder(): Array<any> {

    if (!this.items)
      return [];

    var itemsOnSale = _.filter(this.items, (item) => { return item.state ? item.state.state == 'sale' : true; });
    var itemsToOrder = _.filter(itemsOnSale, (item) => { return item.count > 0; });

    return _.map(itemsToOrder, (item) => {
      return {
        id: item.id,
        count: item.count
      }
    });

  }
  public get noItemsToOrder(): boolean { return this.itemsToOrder.length == 0; }
  public get priceDescription(): string {

    if (!this.cart.itemsPrice)
      return null;

    var vatText = null;
    if (this.cart.itemsPrice.type.includesVAT === true)
      vatText = this.text.items.incVAT;
    else
    if (this.cart.itemsPrice.type.includesVAT === false)
      vatText = this.text.items.excVAT;

    return `${this.cart.itemsPrice.type.label} ${vatText}`;

  }


  constructor(public me: MeService, private ssv: SelectService) { }

  ngOnInit() {

    this.stateFilter = this.getStateFilter();

    this.filteredOptions = this.searchControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.me.call<ICartItemList>('browse.user.cart.itemlist.get', {

      selectedItems: this.ssv.selection.items

    }).subscribe((cart) => {

      this.cart = new CartItemList(cart);

      this.me.call<any>('browse.product.search', {

      }).subscribe((search) => {

        this.search = search;
        this.options = [];
        for (var i = 0; i < search.groups.length; i++)
          this.options = this.options.concat(search.groups[i].items);

      });

    });

  }

  public onRemoveAll() {

    // remove all options
    this.items.length = 0;
    this.ssv.removeAll();

  }

  public onSelected(event: any /*MatAutocompleteSelectedEvent*/) {

    // add to cart
    this.ssv.select(event.option.value.id, 1);

    // leave search intact
    this.searchControl.setValue(this._lastFilterValue);

    // rebuild cart
    this.me.call<ICartItemList>('browse.user.cart.itemlist.get', {

      selectedItems: this.ssv.selection.items

    }).subscribe((cart) => {

      this.cart = new CartItemList(cart);

    });

  }
  
  public export(templateId: string) {

    if (this.isEmpty)
      return;

    this.me.call<any>('browse.documents.export', {

      docIds: _.map(this.items, 'id'),
      templateId: templateId

    }).subscribe((result: any) => {

      if (result.rendered)
        this.me.mediaDownloadAuthenticated(`api/${result.url}`, result.fileName);

    });

  }

  displayFn(option: any): string {
    return option && option.name ? option.name : '';
  }

  private _lastFilterValue: string = null;

  private _filter(value: string, limit: number = 100): any[] {

    if (_.isString(value))
      this._lastFilterValue = value;

    if (!this.options)
      return [];

    const filterValues = this.me.normalizeAndSplitIntoWords(value);
    const _this = this;

    return _.take(
      _.filter(this.options, (item) => {

                // filter state
                const state = item.state.state;
                if (_this.stateFilter[state] === false)
                  return false;

                // filter text
                const terms = _.get(item, 'terms', null) as any[];
                return filterValues.every((filterValue) => {
                  return terms.some((term) => term.startsWith(filterValue));
                });

              }), limit);

  }

  public get isClearVisible(): boolean {
    return this._lastFilterValue ? true : false;
  }

  public clear() {

    this.searchControl.setValue('');
    this._lastFilterValue = null;

  }

  // stateFilter - domain specific

  public stateFilter: any = {
    production: false,
    readyForSale: true,
    sale: true,
    soldOut: true
  };

  public switchState(state: string) {

    if (this.stateFilter[state] === true)
      this.stateFilter[state] = false;
    else
    if (this.stateFilter[state] === false)
        this.stateFilter[state] = true;

    this.setStateFilter();
  //  this.searchControl.setValue(this.searchControl.value, {
  //    emitEvent: true,
  //    emitModelToViewChange: true,
  //    emitViewToModelChange: true
  //  });
  }

  private get stateFilterKey(): string {
    return `stateFilter:${this.me.session.domainId}`;
  }

  private getStateFilter(): any {

    var state = {
      production: false,
      readyForSale: true,
      sale: true,
      soldOut: false
    };

    const stateJson = localStorage.getItem(this.stateFilterKey);
    if (_.isString(stateJson)) {
      const storedState = JSON.parse(stateJson);
      if (_.isObjectLike(storedState))
        state = storedState;
    }

    return state;
  }

  private setStateFilter() {

    localStorage.setItem(this.stateFilterKey, JSON.stringify(this.stateFilter));

  }

  public get stateHint(): string {

    var states: Array<string> = [];
    if (this.stateFilter.production)
      states.push('výroba');
    if (this.stateFilter.readyForSale)
      states.push('připraveno');
    if (this.stateFilter.sale)
      states.push('prodej');
    if (this.stateFilter.soldOut)
      states.push('vyprodáno');
    return _.join(states, ', ');

  }


  // shop UI
  public get showShopUI(): boolean {

    if (this.isEmpty)
      return false;

    if (this.me.isVisitor)
      return true;

    return this.ssv.selection.showShopUI;

  }

  public switchShopUI() {
    this.ssv.selection.showShopUI = !this.ssv.selection.showShopUI;
  }


}
